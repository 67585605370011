import React from 'react';
import { graphql } from 'gatsby';

import IndexLayout from 'layouts/IndexLayout';
import { Selectors, SEO } from 'components';

const SelectorIndex = ({ data }) => {
  return (
    <IndexLayout backgroundColor='black' headerColor='white'>
      <SEO title='Curated playlists from Japanese artists' />
      <Selectors {...data.allStrapiFeatures} includeLatest={true} />
    </IndexLayout>
  );
};

export const query = graphql`
  query {
    allStrapiFeatures(
      sort: { fields: publishedDate, order: DESC }
      filter: { subcategory: { name: { eq: "Selector" } } }
    ) {
      edges {
        node {
          artists {
            englishName
          }
          coverImage {
            url
          }
          slug
          selectorID
        }
      }
    }
  }
`;

export default SelectorIndex;
